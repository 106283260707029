import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="slideshow"
export default class extends Controller {
    static targets = ["checkbox", "label"]

    step = 0;

    connect() {
        setInterval(() => this.slide(), 4000);
    }

    slide() {
        const boxes = this.checkboxTargets;
        const labels = this.labelTargets;
        const sliderWrapper = document.querySelector(".sliderWrapper");

        this.step++;

        if (this.step >= boxes.length) {
            this.step = 0;
        }

        labels.forEach(box => box.classList.remove("active"));

        const box = boxes[this.step];
        const label = labels[this.step];
        label.classList.add("active");

        box.checked = true;
        box.checked = true;

        const offset = -(this.step * 100);

        sliderWrapper.style.transform = `translateX(${offset}%)`;
    }

    click(event) {
        const index = event.target.dataset.index;
        this.step = index - 1;
        this.slide()
    }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="commission"
export default class extends Controller {
    static targets = ["ask", "askAll", "commission", "commissionAll", "input", "inputMarket", "locale", "quantity", "submit", "total", "totalAll", "value", "price"]
    numberFormat;

    connect() {
        const locale = this.localeTarget.value;
        this.numberFormat = new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'});
        this.askTarget.innerHTML = this.numberFormat.format(0);
        this.commissionTarget.innerHTML = this.numberFormat.format(0);
        this.totalTarget.innerHTML = this.numberFormat.format(0);
        this.inputTarget.value = this.numberFormat.format(0);
        this.inputMarketTarget.value = this.numberFormat.format(0);

        const quantity = this.quantityTarget.value;
        if (quantity > 1) {
            this.askAllTarget.innerHTML = this.numberFormat.format(0);
            this.commissionAllTarget.innerHTML = this.numberFormat.format(0);
            this.totalAllTarget.innerHTML = this.numberFormat.format(0);
        }
    }

    updateTable(price, quantity) {
        const ask = this.askTarget;
        const commission = this.commissionTarget;
        const total = this.totalTarget;

        ask.innerHTML = this.numberFormat.format(price);
        commission.innerHTML = this.numberFormat.format(price * 0.1);
        total.innerHTML = this.numberFormat.format(price * 1.1);

        if (quantity > 1) {
            const askAll = this.askAllTarget;
            const commissionAll = this.commissionAllTarget;
            const totalAll = this.totalAllTarget;
            askAll.innerHTML = this.numberFormat.format(price * quantity);
            commissionAll.innerHTML = this.numberFormat.format(price * 0.1 * quantity);
            totalAll.innerHTML = this.numberFormat.format(price * 1.1 * quantity);
        }

    }

    updateCommission() {
        console.log("update Commissions");
        const quantity = this.quantityTarget.value;
        const input = this.inputTarget;
        const inputMarket = this.inputMarketTarget;
        const priceInput = this.priceTarget;

        let cursorPosition = input.selectionStart;
        const valueRaw = this.convertComma(input.value, cursorPosition)
        const value = this.cleanPriceValue(valueRaw, event);
        const price = (Number.parseFloat(value));
        const valueNew = this.numberFormat.format(price);
        const delta = valueNew.length - valueRaw.length

        cursorPosition = this.setCursorPosition(cursorPosition, value, delta, event);

        input.value = this.numberFormat.format(price);
        inputMarket.value = this.numberFormat.format(price * 1.1);
        input.setSelectionRange(cursorPosition, cursorPosition);
        priceInput.value = price;

        this.setSubmitButtonState(price);
        this.updateTable(price, quantity);

    }

    updateCommissionByMarketPrice(event) {
        console.log(" updateCommissionByMarketPrice", event.data);

        const quantity = this.quantityTarget.value;
        const input = this.inputTarget;
        const inputMarket = this.inputMarketTarget;
        const priceInput = this.priceTarget;

        let cursorPosition = inputMarket.selectionStart;
        const valueRaw = this.convertComma(inputMarket.value, cursorPosition)
        const value = this.cleanPriceValue(valueRaw, event);
        const price = (Number.parseFloat(value));
        const valueNew = this.numberFormat.format(price);
        const delta = valueNew.length - valueRaw.length

        cursorPosition = this.setCursorPosition(cursorPosition, value, delta, event);

        input.value = this.numberFormat.format(price / 1.1);
        inputMarket.value = this.numberFormat.format(price);
        inputMarket.setSelectionRange(cursorPosition, cursorPosition);
        priceInput.value = price;

        this.setSubmitButtonState(price);
        this.updateTable(price / 1.1, quantity);
    }

    cleanPriceValue(value, event) {
        // remove everything except d .
        value = value.replace(/[^0-9.,]/g, "").replaceAll(",", "");
        // remove all decimal places from the third digit onwards
        if (value.indexOf(".") > 0) {
            console.log("  . ", value);
            value = value.substring(0, value.indexOf('.') + 3);
        }
        // input deleted
        if (!event.data) {
            // comma has been removed
            if (value.indexOf('.') === -1 && value.length > 0) {
                value = value.substring(0, value.length - 2) + "." + value.substring(value.length - 2, value.length);
            }
        }
        console.log('value', value);
        return value || 0;
    }

    setCursorPosition(cursorPosition, value, delta, event) {
        //  input added
        if (event.data) {
            // comma added - push cursor
            if (delta === 1) {
                cursorPosition++;
                console.log("push index to", cursorPosition);
            }
            // 1€0.00
            if (cursorPosition === 1) {
                cursorPosition++;
                console.log("push2 index to", cursorPosition);
            }
            // €01.00
            if (value.length > 4 && value[0] === '0') {
                cursorPosition--;
                console.log("pull4 index to", cursorPosition);
            }
            // do not move cursor on invalid input
            if (/[^.,\d]/.test(event.data)) {
                cursorPosition--;
            }

        } else { // input deleted
            // comma removed - pull cursor
            if (!value) {
                console.log("undefined");
                cursorPosition = 2;
            }

            if (delta === -1) {
                cursorPosition--;
            }
        }
        return cursorPosition;
    }

    convertComma(value, cursorPosition) {
        // convert comma input to point
        if (/[.,]/.test(event.data)) {
            value = value.replace(".", "");
            value = value.substring(0, cursorPosition - 1) + "." + value.substring(cursorPosition);
        }
        return value;
    }

    setSubmitButtonState(price) {
        const submitButton = this.submitTarget;

        if (price && price > 0) {
            submitButton.removeAttribute("disabled")
        } else {
            submitButton.setAttribute("disabled", true)
        }
    }

    updateInput() {
        const input = this.inputTarget;
        const price = Number.parseFloat(input.value);

        if (!isNaN(price)) {
            input.value = this.numberFormat.format(price);
        }

        const inputMarket = this.inputMarketTarget;
        const marketPrice = Number.parseFloat(inputMarket.value);

        if (!isNaN(marketPrice)) {
            inputMarket.value = this.numberFormat.format(marketPrice);
        }
    }

}
